export const theme = {
  direction: 'rtl',
  FONT_SIZE_SMALL: 12,
  FONT_SIZE_MEDIUM: 14,
  FONT_SIZE_LARGE: 16,
  FONT_WEIGHT_LIGHT: '200',
  FONT_WEIGHT_MEDIUM: '500',
  FONT_WEIGHT_BOLD: '700',
  BACKGROUND_COLOR_LIGHT: '#f0f6f7',
  CONTAINER_PADDING: 20,
  TEXT_INPUT_PADDING: 10,
  LOGO_MAIN: '/assets/pngs/logo.png',
  LOGO_WHITE: '/assets/pngs/logoWhite.png',
  LOGO_FLOWVU: '/assets/pngs/FlowVULogo.png',
  LOGO_EYE: '/assets/pngs/logoEye.png',
  PROFILE: '/assets/pngs/profile.png',
  TERMS_OF_SERVICE: 'https://www.vocn.org/terms-of-use-vetarinarian',
  PRIVACY_POLICY: 'https://www.vocn.org/privacy-policy-vetarinarian',
  COMPANY_NAME: 'Vets On Call.',
  COLOR_LIGHT_BLUE: '#00bee2',
  COLOR_LIGHT_GRAY: '#8a8b8c',
  COLOR_DARK_GRAY: '#929292',
  COLOR_DARK_TEAL: '#006582',
  COLOR_DEEP_TEAL: '#193139',
  COLOR_WHITE: '#FFFFFF',
  COLOR_OFF_WHITE: '#F7F7F7',
  COLOR_GOLD: '#ffd700',
  SCREEN_SM: 600,
  SCREEN_MD: 900,
  SCREEN_LG: 1200,
  SCREEN_XLG: 1536,
  HOMEVU: 'Home',
  CHATVU: 'Chat',
  GLASSVU: 'Devices',
  REMOTEVU: 'Users',
  SCREENVU: 'Screens',
  CAMVU: 'Cameras',
  GROUPVU: 'Groups',
  FLOWVU: 'Flows',
  FILEVU: 'Files',
  VIDEOVU: 'Videos',
  REPORTVU: 'Reports',
  PORTALVU: 'Portals',
  SETTINGS: 'Settings',
  SESSION: 'Session',
  LOGOUT: 'logout',
  COMPANY_NAME_TOTP: 'VetARinarian',
  PRIMARY_COLOR: '#00bee2'
}
